import React from "react";

const IconMail = ({ className, fill = false, duotone = true }) => {
  return (
    <>
      {!fill ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            opacity={duotone ? "0.5" : "1"}
            d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
          <path
            d="M6 8L8.1589 9.79908C9.99553 11.3296 10.9139 12.0949 12 12.0949C13.0861 12.0949 14.0045 11.3296 15.8411 9.79908L18 8"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.5 3H1.5C1.22344 3 1 3.22344 1 3.5V13.5C1 13.7766 1.22344 14 1.5 14H14.5C14.7766 14 15 13.7766 15 13.5V3.5C15 3.22344 14.7766 3 14.5 3ZM13.2375 4.70156L8.30781 8.5375C8.18594 8.63281 8.01562 8.63281 7.89375 8.5375L2.9625 4.70156C2.94391 4.68722 2.93028 4.66741 2.92351 4.64493C2.91674 4.62245 2.91718 4.59841 2.92476 4.57618C2.93234 4.55396 2.94668 4.53466 2.96578 4.521C2.98488 4.50734 3.00777 4.5 3.03125 4.5H13.1687C13.1922 4.5 13.2151 4.50734 13.2342 4.521C13.2533 4.53466 13.2677 4.55396 13.2752 4.57618C13.2828 4.59841 13.2833 4.62245 13.2765 4.64493C13.2697 4.66741 13.2561 4.68722 13.2375 4.70156Z"
            fill="#00253F"
            fill-opacity="0.4"
          />
        </svg>
      )}
    </>
  );
};
export default IconMail;
