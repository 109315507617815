import React, { useEffect, useState } from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import DocumentModal from "../components/DocumentModal";
import UserService from "../service/user.service";
import Avatar from "react-avatar";
import DropdownCustom from "../components/DropdownCustom";
import Swal from "sweetalert2";
import Loader from "../assets/images/loader.gif";

const UserDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [documentModal, setDocumentModal] = useState();
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState("");
  const [selectedPayyroStatus, setSelectedPayyroStatus] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleDocumentOpen = (file) => {
    setDocumentModal(true);
    setSelectedFile(file);
  };

  const getUserDetails = async () => {
    try {
      const response = await UserService.getUserDetails(id);
      setData(response.data.data);
      console.log("getUserDetails", response.data.data);
      setSelectedDocumentStatus(response.data.data.statusOfIncomeDocument);
      setSelectedPayyroStatus(response.data.data.statusOfPayyro);
    } catch (error) {
      showMessage(error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const updatePayyroStatus = async (status) => {
    try {
      const response = await UserService.updatePayyroStatus(id, status);
      console.log("updatePayyroStatus", response);
      getUserDetails();
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  const updateDocumentStatus = async (status) => {
    try {
      const response = await UserService.updateDocumentStatus(id, status);
      console.log("updateDocumentStatus", response);
      getUserDetails();
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  const showMessage = (msg, type) => {
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "toast" },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: "10px 20px",
    });
  };

  useEffect(() => {
    getUserDetails();
  }, [id]);

  const status = [
    { label: "Pending", value: "Pending" },
    { label: "Approve", value: "Approve" },
    { label: "Rejected", value: "Rejected" },
  ];

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[calc(100vh-180px)]">
          <img src={Loader} alt="Loader" className="w-14 h-14" />
        </div>
      ) : (
        <>
          <div className="panel max-w-2xl">
            <div className="pb-5 flex justify-between items-center border-b border-primary/20">
              <h5 className="text-lg font-semibold dark:text-white-light">
                User Profile
              </h5>

              {selectedPayyroStatus && (
                <DropdownCustom
                  data={status}
                  width={"100%"}
                  name="Select Status"
                  setData={setSelectedPayyroStatus}
                  defaultData={selectedPayyroStatus}
                  updatePayyroStatus={updatePayyroStatus}
                  type="payyro"
                />
              )}
            </div>
            <div className="py-5 border-b border-primary/20">
              <div className="flex items-center gap-4 pb-7">
                <div className="h-[75px] w-[75px] md:h-20 md:w-20 rounded-lg overflow-hidden avatar">
                  {data?.latestProfileImage ? (
                    <img
                      src={data?.latestProfileImage}
                      alt="User"
                      className="object-cover bg-cover bg-center w-full h-full"
                    />
                  ) : (
                    <Avatar
                      size={75}
                      name={data?.name}
                      className="rounded-lg overflow-hidden"
                    />
                  )}
                </div>

                <h5 className="text-base font-semibold dark:text-white-light">
                  {data?.name}
                </h5>
              </div>
              <div>
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Name</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.name}
                  </p>
                </div>
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Email</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.email}
                  </p>
                </div>
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Account Type</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.accountType}
                  </p>
                </div>
                {data?.address && (
                  <div className="pb-4 grid sm:grid-cols-3 gap-1">
                    <span className="font-semibold">Address</span>
                    <p className="col-span-2">
                      <strong className="hidden sm:inline-block">: </strong>{" "}
                      {data?.address}
                    </p>
                  </div>
                )}
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Profile Completed</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.isCompleteProfile ? "Yes" : "No"}
                  </p>
                </div>
                {data?.accountNumber && (
                  <div className="pb-4 grid sm:grid-cols-3 gap-1">
                    <span className="font-semibold">Acount No.</span>
                    <p className="col-span-2">
                      <strong className="hidden sm:inline-block">: </strong>{" "}
                      {data?.accountNumber}
                    </p>
                  </div>
                )}
                {data?.bankName && (
                  <div className="grid sm:grid-cols-3 gap-1">
                    <span className="font-semibold">Bank Name</span>
                    <p className="col-span-2">
                      <strong className="hidden sm:inline-block">: </strong>{" "}
                      {data?.bankName}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-between mb-4">
                <h5 className="text-base font-semibold dark:text-white-light">
                  Documents
                </h5>
                {data?.incomedocuments.length > 0 && (
                  <div className="flex gap-3">
                    {selectedDocumentStatus && (
                      <DropdownCustom
                        data={status}
                        width={"100%"}
                        name="Select Status"
                        setData={setSelectedDocumentStatus}
                        defaultData={selectedDocumentStatus}
                        updateDocumentStatus={updateDocumentStatus}
                        type="document"
                      />
                    )}
                  </div>
                )}
              </div>
              {data?.incomedocuments.length > 0 ? (
                data?.incomedocuments?.map((item, index) => {
                  return (
                    <div
                      className="flex items-center justify-between gap-4 my-3"
                      key={index}
                    >
                      <span className="line-clamp-2">{item.name}</span>

                      <div
                        onClick={() => handleDocumentOpen(item.document)}
                        className="border border-primary/20 rounded p-1 cursor-pointer duration-300 hover:bg-primary hover:border-primary group"
                      >
                        <AiFillEye className="h-4 w-4 text-primary/80 group-hover:text-white" />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>Documents Not Found</div>
              )}
            </div>

            {documentModal && (
              <DocumentModal
                open={documentModal}
                handleClickClose={() => setDocumentModal(false)}
                selectedFile={selectedFile}
              />
            )}
          </div>{" "}
        </>
      )}
    </>
  );
};

export default UserDetails;
