import React from "react";

const IconLockDots = ({ className, fill = false, duotone = true }) => {
  return (
    <>
      {!fill ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16Z"
            stroke="currentColor"
            strokeWidth="1.5"
          />
          <path
            opacity={duotone ? "0.5" : "1"}
            d="M6 10V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V10"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <g opacity={duotone ? "0.5" : "1"}>
            <path
              d="M9 16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15C8.55228 15 9 15.4477 9 16Z"
              fill="currentColor"
            />
            <path
              d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
              fill="currentColor"
            />
            <path
              d="M17 16C17 16.5523 16.5523 17 16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16Z"
              fill="currentColor"
            />
          </g>
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.25 9.0625H14.9219V4.6875C14.9219 3.30664 13.8027 2.1875 12.4219 2.1875H7.57812C6.19727 2.1875 5.07812 3.30664 5.07812 4.6875V9.0625H3.75C3.4043 9.0625 3.125 9.3418 3.125 9.6875V17.1875C3.125 17.5332 3.4043 17.8125 3.75 17.8125H16.25C16.5957 17.8125 16.875 17.5332 16.875 17.1875V9.6875C16.875 9.3418 16.5957 9.0625 16.25 9.0625ZM10.5469 13.6914V14.7266C10.5469 14.8125 10.4766 14.8828 10.3906 14.8828H9.60938C9.52344 14.8828 9.45312 14.8125 9.45312 14.7266V13.6914C9.2919 13.5757 9.17156 13.4117 9.10943 13.2232C9.0473 13.0347 9.04659 12.8314 9.10738 12.6425C9.16818 12.4535 9.28736 12.2888 9.44777 12.1719C9.60817 12.055 9.80153 11.992 10 11.992C10.1985 11.992 10.3918 12.055 10.5522 12.1719C10.7126 12.2888 10.8318 12.4535 10.8926 12.6425C10.9534 12.8314 10.9527 13.0347 10.8906 13.2232C10.8284 13.4117 10.7081 13.5757 10.5469 13.6914ZM13.5156 9.0625H6.48438V4.6875C6.48438 4.08398 6.97461 3.59375 7.57812 3.59375H12.4219C13.0254 3.59375 13.5156 4.08398 13.5156 4.6875V9.0625Z"
            fill="#00253F"
            fill-opacity="0.4"
          />
        </svg>
      )}
    </>
  );
};
export default IconLockDots;
