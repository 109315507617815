import serverCall from "../serverCall";

const getAllUser = (body) => {
  const response = serverCall.post(`/users/admin/get-all-user`, body);
  return response;
};

const getUserDetails = (userId) => {
  const response = serverCall.get(`/users/get-profile?userId=${userId}`);
  return response;
};

const updateDocumentStatus = (userId, status) => {
  const response = serverCall.post(
    `/users/admin/update-document-status?userId=${userId}&status=${status}`
  );
  return response;
};

const updatePayyroStatus = (userId, status) => {
  const response = serverCall.post(
    `/users/admin/update-payyro-status?userId=${userId}&status=${status}`
  );
  return response;
};

const updateCategory = (body) => {
  const response = serverCall.patch(`/category/update-category`, body);
  return response;
};

const addCategory = (name) => {
  const response = serverCall.post(`/category/add-category?name=${name}`);
  return response;
};

const deleteCategory = (categoryId) => {
  const response = serverCall.delete(`/category/delete-category/${categoryId}`);
  return response;
};

const UserService = {
  getAllUser,
  getUserDetails,
  updateDocumentStatus,
  updatePayyroStatus,
  addCategory,
  updateCategory,
  deleteCategory,
};

export default UserService;
