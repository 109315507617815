import React from "react";
import { DocumentViewer } from "react-documents";
import { IoMdClose } from "react-icons/io";
import selectedDoc from "../assets/english.pdf";

const DocumentModal = ({ open, handleClickClose, selectedFile }) => {
  return (
    <div>
      {open && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="panel overflow-hidden max-w-[95vh] w-full max-h-[95vh] h-full">
              <div
                onClick={handleClickClose}
                className="cursor-pointer h-7 w-7 flex items-center justify-center rounded-full border border-primary/20 absolute right-3 top-3"
              >
                <IoMdClose className="text-xl" />
              </div>
              <div className="flex justify-between items-center b-b border-primary/20 tab:px-26 px-4 py-4 tab:py-5 max-h-[90vh] overflow-y-auto">
                <h5 className="tab:text-xl text-base">Document Details</h5>
              </div>
              <DocumentViewer
                queryParams="hl=Nl"
                url={selectedFile}
                className="!h-[85vh]"
              ></DocumentViewer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentModal;
