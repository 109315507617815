import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { usePopper } from "react-popper";

const Dropdown = (
  { placement = "bottom-end", offset = [0], btnClassName, button, children },
  forwardedRef
) => {
  const [visibility, setVisibility] = useState(false);

  const referenceRef = useRef();
  const popperRef = useRef();

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset,
          },
        },
      ],
    }
  );

  const handleDocumentClick = (event) => {
    if (
      referenceRef.current?.contains(event.target) ||
      popperRef.current?.contains(event.target)
    ) {
      return;
    }

    setVisibility(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  useImperativeHandle(forwardedRef, () => ({
    close() {
      setVisibility(false);
    },
  }));

  return (
    <>
      <button
        ref={referenceRef}
        type="button"
        className={btnClassName}
        onClick={() => setVisibility(!visibility)}
      >
        {button}
      </button>

      <div
        ref={popperRef}
        style={styles.popper}
        {...attributes.popper}
        className="z-50"
        onClick={() => setVisibility(!visibility)}
      >
        {visibility && children}
      </div>
    </>
  );
};

export default forwardRef(Dropdown);
