import serverCall from "../serverCall";

const getFaq = async (page, limit, search) => {
  const response = await serverCall.get(
    `/faq/getall?page=${page}&limit=${limit}&search=${search}`
  );
  return response;
};

const getOneFaq = async (id) => {
  const response = await serverCall.get(`/faq/${id}`);
  return response;
};

const createFaq = async (data) => {
  const response = await serverCall.post(`/faq/admin/create`, data);
  return response;
};

const updateFaq = async (id, data) => {
  const response = await serverCall.put(`/faq/admin/update/${id}`, data);
  return response;
};

const deleteFaq = async (id) => {
  const response = await serverCall.delete(`/faq/admin/delete/${id}`);
  return response;
};

const FaqService = {
  getFaq,
  createFaq,
  updateFaq,
  deleteFaq,
  getOneFaq,
};
export default FaqService;
