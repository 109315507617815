import { lazy } from "react";
import UserTable from "../pages/UserTable.js";
import UserDetails from "../pages/UserDetails.js";
import Faq from "../pages/faq.js";
const Category = lazy(() => import("../pages/Category.js"));
const Error = lazy(() => import("../components/Error"));
const LoginBoxed = lazy(() => import("../pages/Authentication/LoginBoxed.js"));

const routes = [
  {
    path: "/category",
    element: <Category />,
  },
  {
    path: "/user",
    element: <UserTable />,
  },
  {
    path: "/user/details/:id",
    element: <UserDetails />,
  },
  {
    path: "/Faq",
    element: <Faq />,
  },
  //Authentication
  {
    path: "/login",
    element: <LoginBoxed />,
    layout: "blank",
  },

  {
    path: "*",
    element: <Error />,
    layout: "blank",
  },
];

export { routes };
