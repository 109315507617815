import React, { Fragment, useEffect, useState } from "react";
import { DataTable } from "mantine-datatable";
// import useDialogState from "../../../hook";
import IconEdit from "../components/Icon/IconEdit";
import { useFormik } from "formik";
import { Dialog, Transition } from "@headlessui/react";
import IconX from "../components/Icon/IconX";
import * as Yup from "yup";
import IconTrashLines from "../components/Icon/IconTrashLines";
import Swal from "sweetalert2";
import FaqService from "../service/faq.service";
import IconPlusCircle from "../components/Icon/IconPlusCircle";
import IconPlus from "../components/Icon/IconPlus";
import IconSearch from "../components/Icon/IconSearch";
import CustomPagination from "../components/Layouts/CustomPagination";
import Loader from "../assets/images/loader.gif";

const Faq = () => {
  //   const { loader } = useDialogState();
  const [AddMessage, setAddMessage] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [recordsData, setRecordsData] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterUserText, setFilterUserText] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "id",
    direction: "asc",
  });

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: Yup.object().shape({
      question: Yup.string().required("Please Enter Question"),
      answer: Yup.string().required("Please Enter Answer"),
    }),
    onSubmit: async (values) => {
      const { answer, question } = values;
      try {
        const body = {
          answer,
          question,
        };
        if (!values.id) {
          const response = await FaqService.createFaq(body);
          showMessage("Data Created Successfully!", "success");
        } else {
          const response = await FaqService.updateFaq(values.id, body);
          console.log("updateFaq", response);
          showMessage("Data Updated Successfully!", "success");
        }
        setSelectedId("");
        setAddMessage(false);
        getAllFAQ();
        formik.resetForm();
      } catch (error) {
        showMessage(error.response.data.message, "error");
        console.log(error);
      }
    },
  });

  const getAllFAQ = async () => {
    try {
      setLoading(true);
      const response = await FaqService.getFaq(page, pageSize, filterUserText);
      console.log("response", response);
      setRowData(response.data.data);
      setRecordsData(response.data.totalCount);
      setLoading(false);
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  useEffect(() => {
    getAllFAQ();
  }, [page, pageSize, filterUserText]);

  const handleOpen = (data, type) => {
    if (type === "form") {
      setAddMessage(true);
      setSelectedId(data._id);
      if (data._id) {
        formik.setFieldValue("question", data.question);
        formik.setFieldValue("id", data._id);

        formik.setFieldValue(`answer`, data.answer);
      } else {
        formik.resetForm();
      }
    } else {
      setIsDeleteModal(true);
      setSelectedId(data._id);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const handleDelete = async () => {
    try {
      const response = await FaqService.deleteFaq(selectedId);
      console.log("deleteFaq", response);
      showMessage("Question Deleted Successfully!", "success");
      setIsDeleteModal(false);
      setSelectedId("");
      getAllFAQ();
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  const showMessage = (msg = "", type = "success") => {
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "toast" },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: "10px 20px",
    });
  };

  const handelSearch = (e) => {
    const input = e.target.value.toLowerCase();
    setFilterUserText(input);
  };

  const handleChange = (type, page) => {
    const total = recordsData / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };

  return (
    <div>
      <div className="panel mt-6">
        <div className="mb-4 flex gap-5 items-center justify-between w-full">
          <h5 className="font-semibold text-lg dark:text-white-light">FAQ</h5>
          <div className="relative flex gap-2">
            <input
              type="text"
              onChange={handelSearch}
              value={filterUserText}
              className="form-input ltr:pl-9 rtl:pr-9 ltr:sm:pr-4 rtl:sm:pl-4 ltr:pr-9 rtl:pl-9 peer sm:bg-transparent bg-gray-100 placeholder:tracking-widest"
              placeholder="Search..."
            />
            <button
              type="button"
              className="absolute w-9 h-9 inset-0 ltr:right-auto rtl:left-auto appearance-none peer-focus:text-primary"
            >
              <IconSearch className="mx-auto" />
            </button>
            <button
              type="button"
              className="btn btn-primary flex items-center gap-2 shadow-none"
              onClick={() => handleOpen("", "form")}
            >
              <IconPlusCircle /> Add
            </button>
          </div>
        </div>
        <div className="datatables Advanced-pagination faq-table">
          <>
            <DataTable
              onProgress={"loadingg................."}
              noRecordsText="No results match your search query"
              highlightOnHover
              className="whitespace-nowrap table-hover Advanced-pagination"
              records={rowData}
              columns={[
                {
                  title: "#",
                  render: ({ goal }, index) => (
                    <div className="font-semibold">{index + 1}</div>
                  ),
                },
                {
                  title: "Question",
                  render: ({ question }) => (
                    <div className="font-semibold">{question}</div>
                  ),
                },
                {
                  title: "Answer",
                  render: ({ answer }) => (
                    <div className="font-semibold" title={answer}>
                      {answer}
                    </div>
                  ),
                },
                {
                  title: "Action",
                  render: (data) => (
                    <div className="flex">
                      <button
                        type="button"
                        onClick={() => handleOpen(data, "form")}
                        className="btn btn-primary flex items-center gap-3 shadow-none me-3"
                      >
                        <IconEdit />
                      </button>{" "}
                      <button
                        type="button"
                        onClick={() => handleOpen(data, "delete")}
                        className="btn btn-danger flex items-center gap-3 shadow-none me-3"
                      >
                        <IconTrashLines />
                      </button>
                    </div>
                  ),
                },
              ]}
              totalRecords={recordsData}
              recordsPerPage={pageSize}
              onPageChange={(p) => setPage(p)}
              recordsPerPageOptions={PAGE_SIZES}
              onRecordsPerPageChange={setPageSize}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              minHeight={200}
              loading={loading}
              fetching={loading}
            />
            {rowData?.length !== 0 && (
              <CustomPagination
                total={recordsData}
                page={page}
                handleChange={handleChange}
                dataPerPage={pageSize}
                handleDataPerPage={handleDataPerPage}
                PAGE_SIZES={PAGE_SIZES}
              />
            )}
          </>
        </div>
      </div>

      <Transition appear show={AddMessage} as={Fragment}>
        <Dialog
          as="div"
          open={true}
          onClose={() => setAddMessage(false)}
          className="relative z-[51]"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[black]/60" />
          </Transition.Child>
          <div className="fixed inset-0 z-[999] px-4 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-lg text-black dark:text-white-dark">
                  <button
                    type="button"
                    onClick={() => {
                      setAddMessage(false);
                      formik.resetForm();
                    }}
                    className="absolute top-4 ltr:right-4 rtl:left-4 text-gray-400 hover:text-gray-800 dark:hover:text-gray-600 outline-none"
                  >
                    <IconX />
                  </button>
                  <div className="text-lg font-medium bg-[#fbfbfb] dark:bg-[#121c2c] ltr:pl-5 rtl:pr-5 py-3 ltr:pr-[50px] rtl:pl-[50px]">
                    {/* {params.id ? "Edit Project" : "Add Project"} */}
                    {selectedId ? "Update Question" : "Add Question"}
                  </div>
                  <div className="p-5">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="grid gap-5">
                        <div>
                          <label htmlFor="taskTitle">Question</label>
                          <textarea
                            rows={2}
                            name="question"
                            value={formik.values.question}
                            onChange={formik.handleChange}
                            type="text"
                            className={`form-input ${
                              formik.touched.question && formik.errors.question
                                ? "border border-danger"
                                : ""
                            }`}
                            placeholder="Question"
                          />
                          {formik.touched.question &&
                            formik.errors.question && (
                              <p className="text-danger text-xs mt-1">
                                {formik.errors.question}
                              </p>
                            )}
                        </div>

                        <div>
                          <label htmlFor="taskTitle">Answer</label>
                          <textarea
                            rows={3}
                            name="answer"
                            value={formik.values.answer}
                            onChange={formik.handleChange}
                            type="text"
                            className={`form-input ${
                              formik.touched.answer && formik.errors.answer
                                ? "border border-danger"
                                : ""
                            }`}
                            placeholder="Answer"
                          />
                          {formik.touched.answer && formik.errors.answer && (
                            <p className="text-danger text-xs mt-1">
                              {formik.errors.answer}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="flex justify-end items-center mt-8">
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={() => {
                            setAddMessage(false);
                            formik.resetForm();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary ltr:ml-4 rtl:mr-4"
                        >
                          {selectedId ? "Update" : "Add"}
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          open={isDeleteModal}
          onClose={() => setIsDeleteModal(false)}
          className="relative z-[51]"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[black]/60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center px-4 py-8">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-lg text-black dark:text-white-dark">
                  <button
                    type="button"
                    onClick={() => setIsDeleteModal(false)}
                    className="absolute top-4 ltr:right-4 rtl:left-4 text-gray-400 hover:text-gray-800 dark:hover:text-gray-600 outline-none"
                  >
                    <IconX />
                  </button>
                  <div className="text-lg font-medium bg-[#fbfbfb] dark:bg-[#121c2c] ltr:pl-5 rtl:pr-5 py-3 ltr:pr-[50px] rtl:pl-[50px]">
                    Delete FAQ
                  </div>
                  <div className="p-5 text-center">
                    <div
                      className={`text-white bg-danger ring-danger/30 ring-4 p-4 rounded-full w-fit mx-auto`}
                    >
                      <IconTrashLines className="w-7 h-7 mx-auto" />
                    </div>
                    <div className="sm:w-3/4 mx-auto mt-5">
                      Are you sure you want to delete this FAQ?
                    </div>

                    <div className="flex justify-center items-center mt-8">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => setIsDeleteModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`btn btn-danger ltr:ml-4 rtl:mr-4`}
                        onClick={handleDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Faq;
